import React from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { push, replace } from "connected-react-router"
import {
	delayedDispatch,
	setBreadcrumb,
	setLoader,
	setTitle,
	updateCrumb,
} from "store/actions"
import { ContentWrapper, Icon, Map, Table } from "components"
import {
	Col,
	DatePicker,
	Drawer,
	Input,
	notification,
	Row,
	Select,
	Switch,
	Tooltip,
} from "antd"
import { API, Endpoints } from "utils/api"
import Dropzone from "react-dropzone"
import Geocode from "react-geocode"
import moment from "moment"
import { LANGUAGES, translate } from "utils/utils"
import Strings from "utils/strings"

import "./styles.scss"
import excelImage from "assets/images/excel.png"
import pdfImage from "assets/images/pdf.png"
import { QuestionCircleOutlined } from "@ant-design/icons"
import lodash from "lodash"

class ContractDetail extends React.Component<any, any> {
	searchTimer: any

	constructor(props: any) {
		super(props)

		this.state = {
			name: "",
			searchAddress: "",
			workAreas: [{ start: null, end: null }],
			attachments: [],
			hasUnsavedFields: false,
			isActive: true,
			basePoint: {
				type: "Point",
				coordinates: [],
			},
			language: "pt",
			filesToDelete: [],
			drivers: [],
			vehicles: [],
			operators: [],
			shifts: [],
			defaultDrivers: [],
			defaultVehicles: [],
			defaultOperators: [],
			defaultShifts: [],
			defaultContractTypes: [],
			busParams: {},
			preProcessingParameters: {},
			functionalities: {},
			smsOptions: {},
			scannerEmailTemplate: {},
		}
	}

	async componentDidMount() {
		const { dispatch } = this.props

		dispatch(setTitle(this.state.contract?.description || ""))

		await this.getData()
		this.setBreadcrumb()
	}

	componentDidUpdate() {
		const { contract } = this.state
		const { dispatch, match } = this.props

		dispatch(updateCrumb())

		if (match?.params?.contractId === "new") {
			dispatch(setTitle(Strings.contracts.new))
		} else {
			const string = contract?.type?.name + " "

			let date = ""

			if (contract.startDate && contract.endDate) {
				date = `${moment.utc(contract.startDate).format("DD/MM/YYYY")} - ${moment
					.utc(contract.endDate)
					.format("DD/MM/YYYY")}`
			}

			dispatch(setTitle(`${string}${date ? ` (${date})` : ""}` || ""))
		}
	}

	async getData() {
		const { dispatch, match } = this.props
		const { id, contractId } = match.params || {}

		dispatch(setLoader(true))

		try {
			if (contractId !== "new") {
				const [
					response,
					driversResponse,
					vehiclesResponse,
					staffResponse,
					shiftResponse,
					contractTypeResponse,
				] = await Promise.all([
					await API.get({
						url: Endpoints.uriBusinesses(`${id}/contracts/${contractId}`),
					}),
					await API.get({
						url: Endpoints.uriStaff("driver"),
					}),
					await API.get({
						url: Endpoints.uriVehicles(),
					}),
					await API.get({
						url: Endpoints.uriStaff("operator"),
					}),
					await API.get({
						url: Endpoints.uriShifts(),
					}),
					await API.get({
						url: Endpoints.uriContractTypes(),
					}),
				])

				if (
					response.ok &&
					driversResponse.ok &&
					vehiclesResponse.ok &&
					staffResponse.ok &&
					shiftResponse.ok &&
					contractTypeResponse.ok
				) {
					const { contract } = response.data.results || {}
					const { staff: defaultDrivers = [] } =
						driversResponse.data.results || {}
					const { vehicles: defaultVehicles = [] } =
						vehiclesResponse.data.results || {}
					const { staff: defaultOperators = [] } =
						staffResponse.data.results || {}
					const { shifts: defaultShifts = [] } =
						shiftResponse.data.results || {}
					const { contractTypes: defaultContractTypes = [] } =
						contractTypeResponse.data.results || {}
					this.setState({
						contract,
						defaultDrivers,
						defaultVehicles,
						defaultOperators,
						defaultShifts,
						defaultContractTypes,
						searchAddress: contract?.address || "",
						...contract,
						type: contract?.type,
					})
				} else {
					notification.error({
						message: Strings.serverErrors.title,
						description:
							response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					})
				}
			} else {
				const [
					response,
					driversResponse,
					vehiclesResponse,
					staffResponse,
					shiftResponse,
					contractTypeResponse,
				] = await Promise.all([
					await API.get({
						url: Endpoints.uriBusinesses(id),
					}),
					await API.get({
						url: Endpoints.uriStaff("driver"),
					}),
					await API.get({
						url: Endpoints.uriVehicles(),
					}),
					await API.get({
						url: Endpoints.uriStaff("operator"),
					}),
					await API.get({
						url: Endpoints.uriShifts(),
					}),
					await API.get({
						url: Endpoints.uriContractTypes(),
					}),
				])

				if (
					response.ok &&
					driversResponse.ok &&
					vehiclesResponse.ok &&
					staffResponse.ok &&
					shiftResponse.ok &&
					contractTypeResponse.ok
				) {
					const { business } = response.data.results || {}
					const { drivers: defaultDrivers = [] } =
						driversResponse.data.results || {}
					const { vehicles: defaultVehicles = [] } =
						vehiclesResponse.data.results || {}
					const { staff: defaultOperators = [] } =
						staffResponse.data.results || {}
					const { shifts: defaultShifts = [] } =
						shiftResponse.data.results || {}
					const { contractTypes: defaultContractTypes = [] } =
						contractTypeResponse.data.results || {}
					this.setState({
						business,
						defaultDrivers,
						defaultVehicles,
						defaultOperators,
						defaultShifts,
						defaultContractTypes,
					})
				} else {
					dispatch(replace(`/businesses/${id}`))

					notification.error({
						message: Strings.businesses.contracts,
						description:
							response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					})
				}
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async saveContract() {
		const {
			name,
			startDate,
			endDate,
			type,
			description,
			basePoint,
			workAreas,
			drivers,
			vehicles,
			operators,
			shifts,
			warehouseId,
			searchAddress,
			busParams,
			preProcessingUrl,
			preProcessingParameters,
			functionalities,
			smsOptions,
			scannerEmailTemplate,
			loadSequenceType,
			smsTestModePhoneList,
		} = this.state

		const { match, dispatch } = this.props
		const { id, contractId } = match.params || {}

		if (!this.isValidContract()) {
			return
		}

		Object.keys(busParams).forEach((key) => {
			// key === "" is not a valid key, so we delete it
			if (key === "" || busParams[key] === "") {
				delete busParams[key]
				return
			} else {
				// Try to convert to number, if not possible, keep string
				const value = busParams[key]
				const numValue = Number(value)

				if (
					typeof value === "string" &&
					value.trim() !== "" &&
					!Number.isNaN(numValue)
				) {
					busParams[key] = numValue
				}
			}
		})
		Object.keys(preProcessingParameters).forEach((key) => {
			// key === "" is not a valid key, so we delete it
			if (key === "" || preProcessingParameters[key] === "") {
				delete preProcessingParameters[key]
				return
			} else {
				// Try to convert to number, if not possible, keep string
				const value = preProcessingParameters[key]
				const numValue = Number(value)

				if (
					typeof value === "string" &&
					value.trim() !== "" &&
					!Number.isNaN(numValue)
				) {
					preProcessingParameters[key] = numValue
				}
			}
		})

		const body = {
			name: name,
			type: type._id,
			startDate,
			endDate,
			description,
			address: searchAddress,
			basePoint,
			workAreas,
			drivers,
			vehicles,
			operators,
			shifts,
			warehouseId,
			busParams,
			preProcessingUrl,
			preProcessingParameters,
			functionalities,
			smsOptions,
			scannerEmailTemplate,
			loadSequenceType,
			smsTestModePhoneList,
		}

		dispatch(setLoader(true))

		let response: any
		try {
			const isNew = contractId === "new"
			const request = isNew ? API.post : API.put

			response = await request({
				url: Endpoints.uriBusinesses(
					isNew ? `${id}/contracts/` : `${id}/contracts/${contractId}`
				),
				data: body,
			})

			if (response.ok) {
				const { contract } = response.data.results || {}
				this.setState({ contract, ...contract, hasUnsavedFields: false })

				if (isNew) {
					dispatch(push(`/businesses/${id}/contracts/${contract._id}`))
					dispatch(setBreadcrumb(null))
					this.setBreadcrumb()
				}

				notification.success({
					message: Strings.businesses.contracts,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.businesses.contracts,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	setBreadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const string = this.state.contract?.type?.name + " "
				let date = ""

				if (this.state.contract?.startDate && this.state.contract?.endDate) {
					date = `${moment.utc(this.state.contract?.startDate).format("DD/MM/YYYY")} - ${moment
						.utc(this.state.contract?.endDate)
						.format("DD/MM/YYYY")}`
				}

				return {
					locations: [
						{
							icon: "partner",
							text: Strings.sidebar.businesses,
							route: "/businesses",
						},
						{
							icon: "partner",
							text: this.state.business?.name,
							route: `/businesses/${this.props.match.params.id}`,
						},
						{
							text:
								this.props.match.params.contractId === "new"
									? Strings.contracts.new
									: `${string}${date ? ` (${date})` : ""}`,
							icon:
								this.props.match.params.contractId === "new"
									? "plus"
									: "pencil-outline",
						},
					],
					actions: [
						{
							type: "switch",
							value: this.state.isActive,
							onClick: (value: boolean) =>
								this.setState({ isActive: value, hasUnsavedFields: true }),
							text: Strings.generic.active,
							small: {
								label: true,
								switch: true,
							},
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							onClick: () => this.saveContract(),
							separator: "left",
							isSave: true,
						},
					],
				}
			})
		)
	}

	isValidContract() {
		const { startDate, endDate, type, basePoint } = this.state

		if (!startDate) {
			notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.startDateMandatory,
				placement: "bottomRight",
				duration: 5,
			})

			return false
		}

		if (!endDate) {
			notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.endDateMandatory,
				placement: "bottomRight",
				duration: 5,
			})

			return false
		}

		if (!type) {
			notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.typeMandatory,
				placement: "bottomRight",
				duration: 5,
			})

			return false
		}

		if (!basePoint || basePoint?.coordinates.length <= 0) {
			notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.baseLocationAddressMandatory,
				placement: "bottomRight",
				duration: 5,
			})

			return false
		}

		if (!this.canAddWorkArea()) {
			notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.invalidWorkAreaInterval,
				placement: "bottomRight",
				duration: 5,
			})
			return false
		}

		return true
	}

	async searchAddress(address: any) {
		if (!address) return
		try {
			Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "")
			Geocode.fromAddress(address).then(
				(response: any) => {
					const { lat, lng } = response.results[0].geometry.location
					this.setState({
						//basePoint: [lng, lat]
						basePoint: {
							type: "Point",
							coordinates: [lng, lat],
						},
						hasUnsavedFields: true,
					})
				},
				(error: any) => {
					console.error(error)
				}
			)
		} catch (err) {
			console.log("Error retrieving address information")
		}
	}

	async submitAttachment() {
		const { tempAttachment, filesToDelete } = this.state
		const { dispatch, match } = this.props
		const { file, description } = tempAttachment
		const { id, contractId } = match.params || {}

		if (!file) {
			return notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.attachmentMandatory,
				placement: "bottomRight",
				duration: 5,
			})
		}

		if (!translate(description)) {
			return notification.warn({
				message: Strings.businesses.contracts,
				description: Strings.contracts.descriptionMandatory,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(true))

		let response: any
		try {
			const request = tempAttachment?._id ? API.put : API.post

			const body = new FormData()
			body.append("file", file)
			body.append("description", JSON.stringify(description))
			body.append("filesToDelete", JSON.stringify(filesToDelete))

			if (tempAttachment?._id) {
				body.append("_id", tempAttachment?._id)
			}

			response = await request({
				url: Endpoints.uriBusinesses(
					`${id}/contracts/${contractId}/attachments/${tempAttachment?._id || ""}`
				),
				data: body,
			})

			if (response.ok) {
				const { contract } = response.data.results || {}
				this.setState({
					contract,
					...contract,
					tempAttachment: null,
					showAttachmentsDrawer: false,
				})

				notification.success({
					message: Strings.contracts.attachments,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.contracts.attachments,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async deleteAttachment(attachment: string) {
		const { dispatch, match } = this.props
		const { id, contractId } = match.params || {}

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.delete({
				url: Endpoints.uriBusinesses(
					`${id}/contracts/${contractId}/attachments/${attachment}`
				),
			})

			if (response.ok) {
				const { contract } = response.data.results || {}
				this.setState({ contract, ...contract })

				notification.success({
					message: Strings.contracts.attachments,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.contracts.attachments,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	canAddWorkArea() {
		const { workAreas } = this.state
		const copyWorkAreas = JSON.parse(JSON.stringify(workAreas))

		let canAdd = workAreas.length === 0

		if (canAdd) {
			return true
		} else {
			const index = copyWorkAreas.length - 1
			const last = copyWorkAreas[index]

			if (last.start !== null && last.end !== null) {
				if (
					last.end < last.start ||
					last.start < 1000000 ||
					last.start > 9999999 ||
					last.end < 1000000 ||
					last.end > 9999999
				) {
					this.invalidInterval()

					return false
				}

				copyWorkAreas.splice(index, 1)

				for (const area of copyWorkAreas) {
					if (
						(last.start >= area.start && last.start <= area.end) ||
						(last.end >= area.start && last.end <= area.end) ||
						(last.start < area.start && last.end > area.end)
					) {
						this.invalidInterval()

						return false
					}
				}

				return true
			}
		}
	}

	invalidInterval() {
		notification.warn({
			message: Strings.businesses.contracts,
			description: Strings.contracts.invalidWorkAreaInterval,
			placement: "bottomRight",
			duration: 5,
		})
	}

	renderGeneralInformation() {
		const {
			name,
			business,
			description,
			type,
			startDate,
			endDate,
			defaultContractTypes,
			loadSequenceType,
		} = this.state

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.generic.generalInformation}</h2>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={8}>
						<label htmlFor="contract_name" className="InputLabel">
							{Strings.contracts.name}
						</label>
						<Input
							id="contract_name"
							style={{ resize: "none" }}
							placeholder={Strings.contracts.name}
							value={name || ""}
							onChange={(e: any) =>
								this.setState({ name: e.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="business_name" className="InputLabel">
							{Strings.contracts.business}
						</label>
						<Input id="business_name" value={business?.name || "-"} readOnly />
					</Col>
					<Col xs={24} md={8}>
						<label
							htmlFor="contract_type"
							className="InputLabel --label-required"
						>
							{Strings.contracts.type}
						</label>
						<Select
							id="contract_type"
							style={{ width: "100%" }}
							placeholder={Strings.contracts.type}
							showSearch
							disabled={false}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={type?.name || null}
							onChange={(elem: any) => {
								this.setState({
									type: defaultContractTypes.find((ct: any) => ct._id === elem),
									hasUnsavedFields: true,
								})
							}}
						>
							{defaultContractTypes?.map((elem: any) => {
								return (
									<Select.Option value={elem._id}>{elem.name}</Select.Option>
								)
							})}
						</Select>
					</Col>
					<Col xs={24} md={12}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<label htmlFor="contract_description" className="InputLabel">
								{Strings.fields.description}
							</label>
							<Input.TextArea
								id="contract_description"
								style={{ resize: "none", height: "100%" }}
								placeholder={Strings.fields.description}
								value={description || ""}
								onChange={(e: any) =>
									this.setState({
										description: e.target.value,
										hasUnsavedFields: true,
									})
								}
								rows={4}
							/>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<Row gutter={[20, 10]}>
							<Col xs={24} md={12}>
								<label
									htmlFor="contract_start_date"
									className="GenericLabel --label-required"
								>
									{Strings.fields.startDate}
								</label>
								<DatePicker
									id="contract_start_date"
									style={{ width: "100%" }}
									placeholder={Strings.contracts.startDate}
									value={(startDate && moment.utc(startDate)) || null}
									format="DD-MM-YYYY"
									inputReadOnly
									onChange={(value: any) =>
										this.setState({ startDate: value, hasUnsavedFields: true })
									}
								/>
							</Col>
							<Col xs={24} md={12}>
								<label
									htmlFor="contract_end_date"
									className="GenericLabel --label-required"
								>
									{Strings.fields.endDate}
								</label>
								<DatePicker
									id="contract_end_date"
									style={{ width: "100%" }}
									placeholder={Strings.contracts.endDate}
									value={(endDate && moment.utc(endDate)) || null}
									format="DD-MM-YYYY"
									inputReadOnly
									onChange={(value: any) =>
										this.setState({ endDate: value, hasUnsavedFields: true })
									}
								/>
							</Col>
							<Col xs={24} md={12}>
								<label
									htmlFor="contract_load_sequence_type"
									className="GenericLabel"
								>
									{Strings.fields.loadSequenceType}
								</label>

								<Select
									id="contract_load_sequence_type"
									style={{ width: "100%" }}
									disabled={false}
									value={loadSequenceType || ""}
									onChange={(elem: any) => {
										this.setState({
											loadSequenceType: elem,
											hasUnsavedFields: true,
										})
									}}
								>
									{["grid", "pile"].map((elem: any) => {
										return (
											<Select.Option value={elem}>
												{elem.charAt(0).toUpperCase() +
													elem.slice(1).toLowerCase()}
											</Select.Option>
										)
									})}
								</Select>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderLocation() {
		const { basePoint, searchAddress = "", warehouseId = "" } = this.state

		return (
			<ContentWrapper>
				<h2 style={{ marginBottom: 20 }} className="TitleSeparator">
					{Strings.contracts.baseLocation}
				</h2>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<Map
							location={
								(basePoint?.coordinates?.length && {
									lat: basePoint?.coordinates?.[1],
									lng: basePoint?.coordinates?.[0],
								}) ||
								{}
							}
							parent={this}
							editingStyle={null}
							editingLocation="location"
							defaultCenter={{ lat: 38.72152, lng: -9.142161 }}
							center={
								(basePoint?.coordinates?.length && {
									lat: basePoint?.coordinates?.[1],
									lng: basePoint?.coordinates?.[0],
								}) ||
								null
							}
							isMarkerShown
							zoom={12}
							googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
							loadingElement={<div style={{ height: "100%" }} />}
							containerElement={<div style={{ height: "400px" }} />}
							mapElement={<div style={{ height: "100%" }} />}
							//order //to block the marker movement
						/>
					</Col>
					<Col md={18}>
						<label
							htmlFor="contract_search_address"
							className="InputLabel --label-required"
						>
							{Strings.contracts.baseLocationAddress}
						</label>
						<Input
							id="contract_search_address"
							value={searchAddress || ""}
							placeholder={Strings.contracts.baseLocationAddress}
							onChange={(event: any) => {
								const value = event.target.value
								this.setState({ searchAddress: value }, () => {
									clearTimeout(this.searchTimer)
									this.searchTimer = setTimeout(() => {
										if (!this.state.searchAddress) return
										this.searchAddress(searchAddress)
									}, 500)
								})
							}}
							onBlur={(e: any) => {
								if (this.state.searchAddress) {
									this.searchAddress(this.state.searchAddress)
								}
							}}
							onKeyDown={(e: any) => {
								if (e.key === "Enter") {
									this.searchAddress(this.state.searchAddress)
								}
							}}
						/>
					</Col>
					<Col md={6}>
						<label htmlFor="contract_warehouse_id" className="InputLabel">
							{Strings.contracts.warehouseId}
						</label>
						<Input
							id="contract_warehouse_id"
							style={{ resize: "none" }}
							placeholder={Strings.contracts.warehouseId}
							value={warehouseId || ""}
							onChange={(e: any) =>
								this.setState({
									warehouseId: e.target.value,
									hasUnsavedFields: true,
								})
							}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderDrivers() {
		const { drivers, defaultDrivers } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 20 }} className="MultiTitleSeparator">
					<h2>{Strings.staff.drivers}</h2>
				</div>
				<Select
					id="contract_drivers"
					style={{ width: "100%" }}
					placeholder={Strings.staff.drivers}
					showSearch
					showArrow
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					mode="multiple"
					value={drivers}
					onChange={(elem: any) => {
						this.setState({
							drivers: elem,
							hasUnsavedFields: true,
						})
					}}
				>
					{defaultDrivers.map((driver: any) => (
						<Select.Option key={driver._id} value={driver._id}>
							{driver.name}
						</Select.Option>
					))}
				</Select>
			</ContentWrapper>
		)
	}

	renderVehicles() {
		const { vehicles, defaultVehicles } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 20 }} className="MultiTitleSeparator">
					<h2>{Strings.settings.vehicles}</h2>
				</div>
				<Select
					id="contract_vehicles"
					style={{ width: "100%" }}
					placeholder={Strings.settings.vehicles}
					showSearch
					showArrow
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					mode="multiple"
					value={vehicles}
					onChange={(elem: any) => {
						this.setState({
							vehicles: elem,
							hasUnsavedFields: true,
						})
					}}
				>
					{defaultVehicles.map((vehicle: any) => (
						<Select.Option key={vehicle._id} value={vehicle._id}>
							{vehicle.name} ({vehicle.licensePlate})
						</Select.Option>
					))}
				</Select>
			</ContentWrapper>
		)
	}

	renderOperators() {
		const { operators, defaultOperators } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 20 }} className="MultiTitleSeparator">
					<h2>{Strings.staff.operators}</h2>
				</div>
				<Select
					id="contract_vehicles"
					style={{ width: "100%" }}
					placeholder={Strings.staff.operators}
					showSearch
					showArrow
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					mode="multiple"
					value={operators}
					onChange={(elem: any) => {
						this.setState({
							operators: elem,
							hasUnsavedFields: true,
						})
					}}
				>
					{defaultOperators.map((operator: any) => (
						<Select.Option key={operator._id} value={operator._id}>
							{operator.name}
						</Select.Option>
					))}
				</Select>
			</ContentWrapper>
		)
	}

	renderShifts() {
		const { shifts, defaultShifts } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 20 }} className="MultiTitleSeparator">
					<h2>{Strings.fields.shift}</h2>
				</div>
				<Select
					id="contract_vehicles"
					style={{ width: "100%" }}
					placeholder={Strings.fields.shift}
					showSearch
					showArrow
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					mode="multiple"
					value={shifts}
					onChange={(elem: any) => {
						this.setState({
							shifts: elem,
							hasUnsavedFields: true,
						})
					}}
				>
					{defaultShifts.map((shift: any) => (
						<Select.Option key={shift._id} value={shift._id}>
							{shift.name}
						</Select.Option>
					))}
				</Select>
			</ContentWrapper>
		)
	}

	renderWorkAreas() {
		const { workAreas } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 10 }} className="MultiTitleSeparator">
					<h2>{Strings.contracts.workAreas}</h2>
					<button
						onClick={() => {
							if (this.canAddWorkArea()) {
								workAreas.push({
									start: null,
									end: null,
								})

								this.setState({ workAreas })
							}
						}}
					>
						<Icon name="plus" />
					</button>
				</div>
				{this.renderWorkAreasList()}
			</ContentWrapper>
		)
	}

	renderWorkAreasList() {
		const { workAreas } = this.state

		if (!Array.isArray(workAreas) || workAreas.length === 0) {
			return null
		}

		return (
			<Row gutter={[30, 10]}>
				{workAreas.map((workArea: any, index: number) => {
					let startValue = workArea.start || ""
					let endValue = workArea.end || ""

					if (startValue.length > 4) {
						startValue = `${startValue.substring(0, 4)}-${startValue.substring(4)}`
					}

					if (endValue.length > 4) {
						endValue = `${endValue.substring(0, 4)}-${endValue.substring(4)}`
					}

					return (
						<Col key={`work_area_${index}`} xs={24} md={12}>
							<div className="WorkareaEntryBlock">
								<div>
									<label
										htmlFor={`contract_work_area_${index}_start`}
										className="InputLabel"
									>
										{Strings.contracts.startPostcode}
									</label>
									<Input
										id={`contract_work_area_${index}_start`}
										value={startValue}
										style={{ width: "100%" }}
										placeholder="1000-001"
										onChange={(e: any) => {
											const value = e.target.value.replace("-", "")

											if (isNaN(value) || value?.length > 7) {
												return
											}

											workArea.start = value
											this.setState({ workAreas, hasUnsavedFields: true })
										}}
									/>
								</div>
								<div>
									<label
										htmlFor={`contract_work_area_${index}_end`}
										className="InputLabel"
									>
										{Strings.contracts.endPostcode}
									</label>
									<Input
										id={`contract_work_area_${index}_end`}
										value={endValue}
										style={{ width: "100%" }}
										placeholder="2100-500"
										onChange={(e: any) => {
											const value = e.target.value.replace("-", "")

											if (isNaN(value) || value?.length > 7) {
												return
											}

											workArea.end = value
											this.setState({ workAreas, hasUnsavedFields: true })
										}}
									/>
								</div>
								<div className="WorkareaDeleteContainer">
									<button
										className="WorkareaDeleteButton"
										onClick={() => {
											workAreas.splice(index, 1)
											this.setState({ workAreas, hasUnsavedFields: true })
										}}
									>
										<Icon name="trash" />
									</button>
								</div>
							</div>
						</Col>
					)
				})}
			</Row>
		)
	}

	renderFunctionalities() {
		const { functionalities } = this.state
		const functionalitiesArray = [
			{
				label: Strings.contracts.allowCheckOut,
				defaultValue: functionalities?.allowCheckOut || false,
				key: "allowCheckOut",
			},
			{
				label: Strings.contracts.onTransitSms,
				defaultValue: functionalities?.onTransitSms || false,
				key: "onTransitSms",
			},
			{
				label: Strings.contracts.scannerEmail,
				defaultValue: functionalities?.scannerEmail || false,
				key: "scannerEmail",
			},
			{
				label: Strings.contracts.smsTestMode,
				defaultValue: functionalities?.smsTestMode || false,
				key: "smsTestMode",
			},
		]

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 20 }} className="MultiTitleSeparator">
					<h2>{Strings.contracts.functionalities}</h2>
				</div>
				<Row gutter={[20, 10]}>
					{functionalitiesArray.map(({ label, defaultValue, key }) => (
						<div key={`switch_key_${label}`} className="TableSwitch">
							<span className="TableSwitchLabel">{label}</span>
							<Switch
								checked={defaultValue}
								size="default"
								onChange={(value: boolean) => {
									let newFunctionalities = functionalities
									newFunctionalities[key] = value
									this.setState({
										functionalities: newFunctionalities,
										hasUnsavedFields: true,
									})
								}}
							/>{" "}
						</div>
					))}
				</Row>
			</ContentWrapper>
		)
	}

	renderSmsOptions() {
		const { functionalities, smsOptions } = this.state

		if (!functionalities?.onTransitSms && !functionalities?.lastOrder) {
			return null
		}

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.contracts.sms}</h2>
				<Row gutter={[20, 10]}>
					<div style={{ width: "100%" }}>
						<Col xs={24} md={12}>
							<label htmlFor="contract_on_transit_sms" className="InputLabel">
								{Strings.fields.deliverySlotRangeMinutes}
							</label>
							<Input
								id="contract_on_transit_sms_slot_time"
								type="number"
								style={{ resize: "none" }}
								placeholder={Strings.fields.description}
								value={smsOptions?.onTransitSms?.deliverySlotRangeMinutes}
								onChange={(e: any) => {
									let newSmsOptions = smsOptions
									if (newSmsOptions.onTransitSms) {
										newSmsOptions.onTransitSms.deliverySlotRangeMinutes =
											e.target.value
									} else {
										newSmsOptions.onTransitSms = {
											deliverySlotRangeMinutes: e.target.value,
										}
									}
									this.setState({
										smsOptions: newSmsOptions,
										hasUnsavedFields: true,
									})
								}}
							/>
						</Col>
						<Col xs={24} md={12} style={{ marginTop: 10 }}>
							<label
								htmlFor="contract_on_transit_sms"
								className="InputLabel"
								style={{ marginRight: 5 }}
							>
								{Strings.fields.template}
							</label>
							<Tooltip
								placement="top"
								title={
									<div>
										<div>{Strings.contracts.templateVariablesLegend}: </div>
										<div style={{ marginTop: 10 }}>
											{"{{from}} - "}
											{Strings.contracts.templateVariablesFrom}
										</div>
										<div>
											{"{{to}} - "}
											{Strings.contracts.templateVariablesTo}
										</div>
										<div>
											{"{{clientName}} - "}
											{Strings.contracts.templateVariablesClientName}
										</div>
										<div>
											{"{{orderId}} - "}
											{Strings.contracts.templateVariablesOrderId}
										</div>
									</div>
								}
							>
								<QuestionCircleOutlined translate={undefined} />
							</Tooltip>
							<Input.TextArea
								id="contract_on_transit_sms_template"
								style={{ resize: "none" }}
								placeholder={Strings.fields.description}
								value={smsOptions?.onTransitSms?.template || ""}
								onChange={(e: any) => {
									let newSmsOptions = smsOptions
									if (newSmsOptions.onTransitSms) {
										newSmsOptions.onTransitSms.template = e.target.value
									} else {
										newSmsOptions.onTransitSms = { template: e.target.value }
									}
									this.setState({
										smsOptions: newSmsOptions,
										hasUnsavedFields: true,
									})
								}}
								rows={4}
							/>
						</Col>
					</div>
				</Row>
			</ContentWrapper>
		)
	}

	renderSmsTestModeOptions() {
		const {
			functionalities,
			smsTestModePhoneList,
			smsTestModePhoneListSearchValue,
		} = this.state

		if (!functionalities?.smsTestMode) {
			return null
		}

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.contracts.smsTestMode}</h2>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12}>
						<label
							htmlFor="contract_scanner_email_recipients"
							className="InputLabel"
						>
							{Strings.fields.phoneNumber}
						</label>
						<Select
							mode="tags"
							style={{ width: "100%" }}
							searchValue={smsTestModePhoneListSearchValue}
							onSearch={(e: string) => {
								this.setState({ smsTestModePhoneListSearchValue: e })
							}}
							onChange={(e: Array<string>) => {
								// Compare numbers with the ones in the smsTestModePhoneList
								if (lodash.isEqual(e, smsTestModePhoneList)) return

								this.setState({
									smsTestModePhoneListSearchValue: "",
									smsTestModePhoneList: e,
									hasUnsavedFields: true,
								})
							}}
							tokenSeparators={[","]}
							value={smsTestModePhoneList || []}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderScannerEmail() {
		const {
			functionalities,
			scannerEmailTemplate,
			scannerEmailRecipientsSearchValue,
			scannerEmailCCSearchValue,
			scannerEmailBCCSearchValue,
		} = this.state

		if (!functionalities?.scannerEmail) {
			return null
		}

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.contracts.scannerEmail}</h2>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12}>
						<div>
							<label
								htmlFor="contract_scanner_email_subject"
								className="InputLabel"
							>
								{Strings.fields.subject}
							</label>
							<Input
								id="contract_on_transit_sms_slot_time"
								style={{ resize: "none" }}
								value={scannerEmailTemplate?.subject}
								onChange={(e: any) => {
									let newScannerEmailTemplate = scannerEmailTemplate
									newScannerEmailTemplate.subject = e.target.value
									this.setState({
										scannerEmailTemplate: newScannerEmailTemplate,
										hasUnsavedFields: true,
									})
								}}
							/>
						</div>
						<div>
							<label
								htmlFor="contract_scanner_email_recipients"
								className="InputLabel"
							>
								{Strings.fields.recipients}
							</label>
							<Select
								mode="tags"
								style={{ width: "100%" }}
								searchValue={scannerEmailRecipientsSearchValue}
								onSearch={(e: string) => {
									this.setState({ scannerEmailRecipientsSearchValue: e })
								}}
								onChange={(e: Array<string>) => {
									// Check last entry is a valid email
									const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
									if (e && e.length > 0 && !emailRegex.test(e[e.length - 1])) {
										notification.warn({
											message: Strings.fields.recipients,
											description: Strings.fields.invalidEmail,
											placement: "bottomRight",
											duration: 5,
										})
										return
									}

									// Compare recipients emails with the ones in the scannerEmailTemplate
									if (lodash.isEqual(e, scannerEmailTemplate.recipients)) return

									let newScannerEmailTemplate = scannerEmailTemplate
									newScannerEmailTemplate.recipients = e
									this.setState({
										scannerEmailRecipientsSearchValue: "",
										scannerEmailTemplate: newScannerEmailTemplate,
										hasUnsavedFields: true,
									})
								}}
								tokenSeparators={[","]}
								value={scannerEmailTemplate.recipients || []}
							/>
						</div>
						<div>
							<label htmlFor="contract_scanner_email_cc" className="InputLabel">
								CC
							</label>
							<Select
								mode="tags"
								style={{ width: "100%" }}
								searchValue={scannerEmailCCSearchValue}
								onSearch={(e: string) => {
									this.setState({ scannerEmailCCSearchValue: e })
								}}
								onChange={(e: Array<string>) => {
									// Check last entry is a valid email
									const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
									if (e && e.length > 0 && !emailRegex.test(e[e.length - 1])) {
										notification.warn({
											message: "CC",
											description: Strings.fields.invalidEmail,
											placement: "bottomRight",
											duration: 5,
										})
										return
									}

									// Compare CC emails with the ones in the scannerEmailTemplate
									if (lodash.isEqual(e, scannerEmailTemplate.cc)) return

									let newScannerEmailTemplate = scannerEmailTemplate
									newScannerEmailTemplate.cc = e
									this.setState({
										scannerEmailCCSearchValue: "",
										scannerEmailTemplate: newScannerEmailTemplate,
										hasUnsavedFields: true,
									})
								}}
								tokenSeparators={[","]}
								value={scannerEmailTemplate.cc || []}
							/>
						</div>
						<div>
							<label
								htmlFor="contract_scanner_email_bcc"
								className="InputLabel"
							>
								BCC
							</label>
							<Select
								mode="tags"
								style={{ width: "100%" }}
								searchValue={scannerEmailBCCSearchValue}
								onSearch={(e: string) => {
									this.setState({ scannerEmailBCCSearchValue: e })
								}}
								onChange={(e: Array<string>) => {
									// Check last entry is a valid email
									const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
									if (e && e.length > 0 && !emailRegex.test(e[e.length - 1])) {
										notification.warn({
											message: "BCC",
											description: Strings.fields.invalidEmail,
											placement: "bottomRight",
											duration: 5,
										})
										return
									}

									// Compare BCC emails with the ones in the scannerEmailTemplate
									if (lodash.isEqual(e, scannerEmailTemplate.bcc)) return

									let newScannerEmailTemplate = scannerEmailTemplate
									newScannerEmailTemplate.bcc = e
									this.setState({
										scannerEmailBCCSearchValue: "",
										scannerEmailTemplate: newScannerEmailTemplate,
										hasUnsavedFields: true,
									})
								}}
								tokenSeparators={[","]}
								value={scannerEmailTemplate.bcc || []}
							/>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<div>
								<label
									htmlFor="contract_scanner_email_body"
									className="InputLabel"
									style={{ marginRight: 5 }}
								>
									{Strings.fields.template}
								</label>
								<Tooltip
									placement="top"
									title={
										<div>
											<div>{Strings.contracts.templateVariablesLegend}: </div>
											<div style={{ marginTop: 10 }}>
												{"{{totalBoxes}} - "}
												{Strings.contracts.templateVariablesTotalBoxes}
											</div>
										</div>
									}
								>
									<QuestionCircleOutlined translate={undefined} />
								</Tooltip>
							</div>
							<Input.TextArea
								id="contract_scanner_email_body_text"
								style={{ resize: "none", height: "100%" }}
								placeholder={Strings.fields.template}
								value={scannerEmailTemplate?.body || ""}
								onChange={(e: any) => {
									let newScannerEmailTemplate = scannerEmailTemplate
									newScannerEmailTemplate.body = e.target.value
									this.setState({
										scannerEmailTemplate: newScannerEmailTemplate,
										hasUnsavedFields: true,
									})
								}}
								rows={6}
							/>
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderOptimizerParams() {
		const { busParams } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 10 }} className="MultiTitleSeparator">
					<h2>{Strings.contracts.optimizerParams}</h2>
					<button
						onClick={() => {
							let newBusParams = busParams
							newBusParams[""] = ""
							this.setState({ busParams: newBusParams, hasUnsavedFields: true })
						}}
					>
						<Icon name="plus" />
					</button>
				</div>
				{/* For each key in bus params add a row with 2 inputs */}
				<Row gutter={[20, 10]} justify="space-between">
					{Object.keys(busParams).map((key) => {
						return (
							<>
								<Col xs={24} md={7}>
									<Row gutter={[20, 10]}>
										<Col xs={18}>
											<Input
												id={`bus_param_${key}`}
												style={{ resize: "none" }}
												placeholder="Variable"
												value={key || ""}
												onChange={(e: any) => {
													let newBusParams = busParams
													newBusParams[e.target.value] = newBusParams[key]
													delete newBusParams[key]
													this.setState({
														busParams: newBusParams,
														hasUnsavedFields: true,
													})
												}}
											/>
										</Col>
										<Col xs={6}>
											<Input
												id={`bus_param_${key}_value`}
												style={{ resize: "none" }}
												placeholder="Key"
												value={busParams[key] || ""}
												onChange={(e: any) => {
													let newBusParams = busParams
													newBusParams[key] = e.target.value
													this.setState({
														busParams: newBusParams,
														hasUnsavedFields: true,
													})
												}}
											/>
										</Col>
									</Row>
								</Col>
							</>
						)
					})}
				</Row>
			</ContentWrapper>
		)
	}

	renderPreProcessingParams() {
		const { preProcessingUrl, preProcessingParameters } = this.state

		return (
			<ContentWrapper>
				<div style={{ marginBottom: 10 }} className="MultiTitleSeparator">
					<h2>{Strings.contracts.preProcessingParams}</h2>
					<button
						onClick={() => {
							let newPreProcessingParameters = preProcessingParameters
							newPreProcessingParameters[""] = ""
							this.setState({
								preProcessingParameters: newPreProcessingParameters,
								hasUnsavedFields: true,
							})
						}}
					>
						<Icon name="plus" />
					</button>
				</div>
				{/* Url for the pre processing script */}
				<Row gutter={[20, 10]} style={{ marginBottom: 10 }}>
					<Col xs={24} md={7}>
						<div>
							<label
								htmlFor="contract_pre_processing_url"
								className="InputLabel"
							>
								{Strings.contracts.preProcessingUrl}
							</label>
							<Input
								id="contract_pre_processing_url"
								style={{ resize: "none" }}
								value={preProcessingUrl}
								onChange={(e: any) => {
									this.setState({
										preProcessingUrl: e.target.value,
										hasUnsavedFields: true,
									})
								}}
							/>
						</div>
					</Col>
				</Row>

				{/* For each key in bus params add a row with 2 inputs */}
				<Row gutter={[20, 10]} justify="space-between">
					{Object.keys(preProcessingParameters).map((key) => {
						return (
							<>
								<Col xs={24} md={7}>
									<Row gutter={[20, 10]}>
										<Col xs={18}>
											<Input
												id={`bus_param_${key}`}
												style={{ resize: "none" }}
												placeholder="Variable"
												value={key || ""}
												onChange={(e: any) => {
													let newPreProcessingParameters =
														preProcessingParameters
													newPreProcessingParameters[e.target.value] =
														newPreProcessingParameters[key]
													delete newPreProcessingParameters[key]
													this.setState({
														preProcessingParameters: newPreProcessingParameters,
														hasUnsavedFields: true,
													})
												}}
											/>
										</Col>
										<Col xs={6}>
											<Input
												id={`bus_param_${key}_value`}
												style={{ resize: "none" }}
												placeholder="Key"
												value={preProcessingParameters[key] || ""}
												onChange={(e: any) => {
													let newPreProcessingParameters =
														preProcessingParameters
													newPreProcessingParameters[key] = e.target.value
													this.setState({
														preProcessingParameters: newPreProcessingParameters,
														hasUnsavedFields: true,
													})
												}}
											/>
										</Col>
									</Row>
								</Col>
							</>
						)
					})}
				</Row>
			</ContentWrapper>
		)
	}

	renderAttachments() {
		const { attachments } = this.state

		return (
			<Table
				title={{
					icon: "grampo",
					title: Strings.contracts.attachments,
				}}
				style={{ marginBottom: 10 }}
				data={attachments}
				columns={[
					{
						Header: Strings.fields.description,
						id: "description",
						accessor: (row: any) => translate(row.description) || "-",
					},
				]}
				add={{
					onClick: () => this.setState({ showAttachmentsDrawer: true }),
				}}
				actions={{
					view: (original: any) => ({
						onClick: () => window.open(original.file, "_blank"),
					}),
					edit: (original: any) => ({
						onClick: () =>
							this.setState({
								showAttachmentsDrawer: true,
								tempAttachment: JSON.parse(JSON.stringify(original)),
							}),
					}),
					remove: (original: any) => ({
						onClick: () => this.deleteAttachment(original._id),
					}),
				}}
			/>
		)
	}

	renderDrawer() {
		const { showAttachmentsDrawer, tempAttachment } = this.state
		const { mobile } = this.props

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="grampo" />
						<p>
							{tempAttachment?._id
								? Strings.contracts.editAttachment
								: Strings.contracts.addAttachment}
						</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.submitAttachment()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showAttachmentsDrawer: false,
									tempAttachment: null,
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() =>
					this.setState({ tempAttachment: null, showAttachmentsDrawer: false })
				}
				visible={showAttachmentsDrawer}
			>
				{this.renderSidebarContent()}
			</Drawer>
		)
	}

	renderSidebarContent() {
		const { tempAttachment, language } = this.state

		let element: any
		if (tempAttachment?.file?.type?.includes("image")) {
			element = <Icon name="frame" />
		} else if (tempAttachment?.file?.type?.includes("pdf")) {
			element = <img src={pdfImage} alt="excel" />
		} else if (
			tempAttachment?.file?.type?.includes("xls") ||
			tempAttachment?.file?.type?.includes("xlsx") ||
			tempAttachment?.file?.type?.includes("spreadsheet")
		) {
			element = <img src={excelImage} alt="excel" />
		} else {
			element = <Icon name="arquivo" />
		}

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<div className="DrawerLanguagePicker">
						<Select
							optionFilterProp="children"
							onChange={(value: any) => this.setState({ language: value })}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={language}
							showArrow
							dropdownMatchSelectWidth
						>
							{LANGUAGES.map((lang: any, index: number) => {
								return (
									<Select.Option
										key={`select_option_${lang}_${index}`}
										value={lang.value}
									>
										{lang.label}
									</Select.Option>
								)
							})}
						</Select>
					</div>
				</Col>
				<Col xs={24}>
					<label
						htmlFor="attachment_upload"
						className="InputLabel --label-required"
					>
						{Strings.contracts.attachment}
					</label>
					<div className="AttachmentFile">
						<Dropzone
							className="GenericAttachmentUpload"
							id="attachment_upload"
							multiple={false}
							onDrop={(files: any) => {
								const filesToDelete = [] as any

								if (typeof tempAttachment?.file === "string") {
									filesToDelete.push(tempAttachment.file)
								}

								this.setState((prevState: any) => ({
									tempAttachment: {
										...prevState.tempAttachment,
										file: files[0],
									},
									filesToDelete,
								}))
							}}
						>
							{tempAttachment?.file ? (
								<div className="AttachmentFileType --visible">
									{element}
									<p>
										{tempAttachment?.file?.name || Strings.contracts.document}
									</p>
								</div>
							) : (
								<div
									className={`GenericAttachmentUploadOverlay${
										!tempAttachment?.file ? " --visible" : ""
									}`}
								>
									<Icon name="grampo" />
									<span>{Strings.generic.changeFile}</span>
								</div>
							)}
							{tempAttachment?.file && (
								<button
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation()
										const filesToDelete = [] as any

										if (typeof tempAttachment?.file === "string") {
											filesToDelete.push(tempAttachment.file)
										}

										this.setState((prevState: any) => ({
											tempAttachment: {
												...prevState.tempAttachment,
												file: null,
											},
											filesToDelete,
										}))
									}}
									className="GenericAttachmentDelete"
								>
									<Icon name="close" />
								</button>
							)}
						</Dropzone>
					</div>
				</Col>
				<Col xs={24}>
					<label
						htmlFor="attachment_description"
						className="InputLabel --label-required"
					>
						{Strings.fields.description}
					</label>
					<Input.TextArea
						id="attachment_description"
						style={{ resize: "none" }}
						placeholder={Strings.fields.description}
						value={tempAttachment?.description?.[language] || ""}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempAttachment: {
									...prevState.tempAttachment,
									description: {
										...prevState.tempAttachment?.description,
										[language]: value,
									},
								},
							}))
						}}
						rows={4}
					/>
				</Col>
			</Row>
		)
	}

	render() {
		const { match } = this.props
		const { contractId } = match.params || {}

		return (
			<div className="ScreenContractDetail">
				<Helmet>
					<title>{Strings.businesses.contracts}</title>
					<meta
						name="description"
						content="Create or edit contracts for a specific client"
					/>
				</Helmet>
				{this.renderGeneralInformation()}
				{this.renderLocation()}
				{this.renderDrivers()}
				{this.renderVehicles()}
				{this.renderOperators()}
				{this.renderShifts()}
				{this.renderWorkAreas()}
				{this.renderFunctionalities()}
				{this.renderSmsOptions()}
				{this.renderSmsTestModeOptions()}
				{this.renderScannerEmail()}
				{this.renderOptimizerParams()}
				{this.renderPreProcessingParams()}
				{contractId !== "new" && this.renderAttachments()}
				{this.renderDrawer()}
			</div>
		)
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
})

export default connect(mapStateToProps)(ContractDetail)
